import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import News from 'features/media/news';
import PRESS from 'src/utils/pressConstants';
import styles from './media.module.scss';
import MailSubmittedModal from 'components/mailSubmittedModal';
import Alert from 'components/Alert';
import HeroSection from 'components/HeroSection';
const media = props => {
  const { pageContext } = props;
  const { numPages, currentPage } = pageContext;
  const posts = props.data.allContentfulNewsPost.edges;
  const pageData = props.data.allContentfulWebMediaPressOptions.edges[0].node;
  const state = props.location.state || {};
  return (
    <div>
      {state.emailSent === true ? (
        <MailSubmittedModal />
      ) : state.emailSent === false ? (
        <Alert color="danger" msg={state.error} />
      ) : (
        ''
      )}
      <Layout transparentHeader={true} paddingTop={false}>
        <SEO title="Media" />
        <div className={styles.media}>
          <HeroSection
            image={pageData?.heroImage?.image}
            title={pageData?.heroImage?.title}
            subTitle={pageData?.heroImage?.subTitle}
          />
          <News
            posts={posts.map(post => {
              return {
                title: post?.node?.title,
                description: post?.node?.description,
                image: post?.node?.featuredImage?.fluid,
                url: post?.node?.url,
              };
            })}
            tab={PRESS.news}
            pageData={pageData?.pressContact}
            pageNumber={currentPage}
            totalNumberOfPages={numPages}
            changePage={page => {
              navigate(
                page === 1 ? `/${PRESS.newsUrl}/` : `/${PRESS.newsUrl}/${page}`
              );
            }}
          />
        </div>
      </Layout>
    </div>
  );
};
export default media;

export const query = graphql`
  query getNews($skip: Int!, $limit: Int!) {
    allContentfulNewsPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          url
          title
          description
          featuredImage {
            fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulWebMediaPressOptions {
      edges {
        node {
          title
          heroImage {
            title
            subTitle
            image {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          pressContact {
            department
            email
            name
            position
            telephone
            image {
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
